.skills-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .skills-section h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: white;
  }
  
  .skills-section ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .skills-section ul li {
    background-color: rgb(250, 206, 148);
    color: black;
    padding: 10px 20px;
    margin: 10px;
    border-radius: 5px;
    font-size: 1.2em;
  }