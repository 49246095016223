/* Education.css */

.education-section {
    margin: 50px 0;
    text-align: center;
    color: #333;
  }
  
  .education-section h2 {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .education-section h3 {
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 0px;
    margin-top: 0;
  }
  
  .education-section h4 {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 20px;
    color: #f5f5f5;
    margin-top: 0px; /* adjust this value as needed */

  }
  
  .education-section ul {
    list-style-type: none; /* remove bullet points */
    padding: 0; /* remove padding */
    margin-bottom: 30px;
    margin-top: 0; /* adjust this value as needed */

  }
  
  .education-section ul li {
    font-size: 1.2em;
    line-height: 1.5; /* add space between lines */
  }
  
  .education-section p {
    font-size: 1.2em;
    line-height: 1.5;
    max-width: 600px; /* prevent line from getting too long */
    margin: 0 auto; /* center the paragraph if it doesn't take up full width */
  }

  .university-image {
    width: 150%; /* to ensure the image takes up the full width of the parent */
    max-width: 400px; /* to set a maximum width for the image */
    height: auto; /* to maintain aspect ratio */
    border-radius: 20%; /* round the corners */
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* subtle drop shadow */
    margin: 0 auto; /* to center the image */
    display: block; /* to make sure margins are applied correctly */
    margin-bottom: 0; /* adjust this value as needed */

}
  