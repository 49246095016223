@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');


body {
    font-family: 'Montserrat', sans-serif;
    color: #f5f5f5;
    background-color: #333;
    margin: 0;
    line-height: 1.6;
    padding-top: 70px;
}

.container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #474747;
    color: #f5f5f5;
    padding: 0;
    text-align: center;
    z-index: 1000; /* to keep the header on top of other content */
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.social-links {
    display: flex;
    align-items: center;
}
  
.social-link {
    width: 80px;
    height: auto;
    margin-right: 10px;
}

header h1 {
    margin: 0;
    padding: 0;
    line-height: 70px;
}
  
nav {
    margin-top: 0;
}
  
nav a {
    color: #f5f5f5;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s ease;
}
  
nav a:hover {
    color: #FFC371;
}




section h2 {
    font-weight: 500;
    font-size: 26px;
    color: #f5f5f5;
    margin-bottom: 20px;
}

section p, section ul li {
    font-size: 18px;
    color: #d8d8d8;
}

ul {
    padding: 0;
    list-style: none;
}

ul li {
    margin-bottom: 10px;
}

.profile-pic {
    float: left;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
    object-position: 70% 30%;
}

.about-content {
    clear: both;
    
}

.project-button {
    position: relative;
    display: block;
    border: none;
    padding: 0;
    margin: 10px;
    width: 250px;
    height: 250px;
    overflow: hidden;
}
  
.project-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    opacity: 0.5;
}

.project-button::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0); /* initial color - transparent */
    transition: background-color 0.3s ease; /* transition effect */
}
  
.project-button:hover::after {
    background-color: grey /* on hover - white with 30% opacity */
}
  
.project-name {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #000;
    z-index: 2;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
}

.project-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
  
.fdm-project {
    width: 300px;
    height: auto;
    margin: 5px;
}

.weather-app-project {
    width: 150px;
    height: auto;
    margin: 5px;
}

.experience-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.experience-button {
    position: relative;
    display: block;
    border: none;
    padding: 0;
    margin: 10px;
    width: 200px;
    height: 200px;
    overflow: hidden;
}
  
.experience-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
    opacity: 0.7;
}

.experience-button::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0); /* initial color - transparent */
    transition: background-color 0.3s ease; /* transition effect */
}


.experience-button:hover::after {
    background-color: grey /* on hover - white with 30% opacity */
}


.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.close-button {
    font-size: 18px;
    padding: 10px 20px;
    background-color: #ff6347;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    top: 10px;
    right: 10px;
}

.social-links {
    align-items: center;
}

.social-link {
    width: 100px;
    height: auto;
    margin-right: 0px;
}

.github-link {
    display: flex;
    align-items: center;
    gap: 10px;
}

.github-link img {
    width: 150px; 
    height: auto;  
}

